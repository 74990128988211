<template>
  <footer class="footer">
    <div>
      <font-awesome-icon class="copyright-icon" icon="copyright" />
      2021 {{ user.name }} |
      <a href="https://github.com/webor" target="_blank">Get cool projects</a>
      !
    </div>
    <SocialBar :links="user.social.links" />
  </footer>
</template>

<script>
import SocialBar from "./SocialBar";
export default {
  name: "Footer",
  props: ["user"],
  components: {
    SocialBar,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

$bg-footer: map-get($colors, primary) !default;

.footer {
  padding: 10px 20px 10px 20px;
  background-color: $bg-footer;
  color: map-get($colors, light);
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  align-items: center;
}

.copyright-icon {
  font-size: 1.5rem;
  vertical-align: middle;
}

::v-deep .social-wrap {
  float: right;
  a {
    color: white;
  }
  .icon {
    font-size: 2.8rem;
    &:hover {
      color: map-get($colors, light);
    }
  }
}

::v-deep li {
  margin-bottom: 0 !important;
}
</style>