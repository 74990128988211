
import { defineComponent } from "vue";
import LandingPage from "../components/LandingPage.vue";
import Navbar from "../components/Navbar.vue";
import Description from "../components/Description.vue";
import Skills from "../components/Skills.vue";
import Projects from "../components/Projects.vue";
import Footer from "../components/Footer.vue";
import user from "../jsons/user.json";
import Certification from "../components/Certification.vue";

export default defineComponent({
  name: "Home",
  components: {
    LandingPage,
    Navbar,
    Description,
    Skills,
    Projects,
    Footer,
    Certification
  },
  data: () => ({
    isLoaded: false,
    showNavbar: true,
    lastScrollPosition: 0,
    user: user,
    posts: [],
  }),
  methods: {
    fetchPosts() {
      return {};
    },
    fetchUser() {
      return {};
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
  created(): void {
    Promise.all([this.fetchPosts(), this.fetchUser()]).then(
      () => {
        document.body.classList.add("loading");
        this.isLoaded = true;
      }
    );
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
});
